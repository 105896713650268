import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';

const App = () => {
  const webcamRef = useRef(null);
  const [model, setModel] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [pixelToCmRatio, setPixelToCmRatio] = useState(0); // Ratio of pixels to centimeters

  useEffect(() => {
    const loadModel = async () => {
      const loadedModel = await cocoSsd.load();
      setModel(loadedModel);
    };
    loadModel();
  }, []);

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (model && imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = async () => {
        const predictions = await model.detect(img);
        setPredictions(predictions);
        calculatePixelToCmRatio(img); // Calculate the pixel to cm ratio based on a reference object
      };
    }
  };

  // Function to calculate the pixel-to-cm ratio based on a known reference object
  const calculatePixelToCmRatio = (img) => {
    const referenceWidthCm = 8.56; // Example: width of a credit card in cm
    const referenceObject = predictions.find((pred) => pred.class === 'person'); // Adjust based on reference object class
    if (referenceObject) {
      const referencePixelWidth = referenceObject.bbox[2]; // Get width in pixels
      const ratio = referenceWidthCm / referencePixelWidth; // Calculate pixels per cm
      setPixelToCmRatio(ratio);
    }
  };

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
      />
      <button onClick={capture}>Capture and Detect</button>
      <div>
        {predictions.map((prediction, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              border: '2px solid red',
              left: prediction.bbox[0],
              top: prediction.bbox[1],
              width: prediction.bbox[2],
              height: prediction.bbox[3],
            }}
          >
            <span style={{ color: 'red' }}>{prediction.class}</span>
            {/* Display dimensions in pixels */}
            <span style={{ color: 'red' }}>
              {Math.round(prediction.bbox[2])}x{Math.round(prediction.bbox[3])} pixels
            </span>
            {/* Display dimensions in cm */}
            <span style={{ color: 'green' }}>
              {pixelToCmRatio > 0 &&
                `(${(prediction.bbox[2] * pixelToCmRatio).toFixed(2)}cm x ${(prediction.bbox[3] * pixelToCmRatio).toFixed(2)}cm)`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
